// import React, { useState, useEffect } from 'react';
// import { GoogleLogin } from 'react-google-login';
// import axios from 'axios';
// import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import Courses from './Courses';
// import UnitPage from './UnitPage';
// import QuestionsPage from './QuestionsPage';
// import LiveResponsesPage from './LiveResponsesPage';
// import Scores from './Scores';
// import { WebSocketProvider } from './WebSocketContext';

// function ProtectedRoute({ children, userIsValid, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => (userIsValid ? React.cloneElement(children, props) : <Redirect to="/" />)}
//     />
//   );
// }

// function App() {
//   const storedUser = JSON.parse(localStorage.getItem('user')||'null');;
//   const [user, setUser] = useState(null);
//   const [profile, setProfile] = useState({});
//   const [loggedOut, setLoggedOut] = useState(false);
//   const [userIsValid, setIsValidUser] = useState(false);
//   const [showInvalidUserPopup, setShowInvalidUserPopup] = useState(false);
//   const history = useHistory();
//   const [jwtToken, setJwtToken] = useState('');

//   const responseGoogle = (response) => {
    
//     console.log("Google Login Response:", response);
    
//     if (response.error) {
//       console.log("An error occurred", response.error);
//     }

//     const tokenId = response.tokenId;
//     console.log("Token-Id", tokenId);
//     if (tokenId) {
//       // Use the token to authenticate the user on your backend
//       axios.post(`http://localhost:5000/login`,{},{
//         headers:{
//           Authorization: `Bearer ${tokenId}`,
//           Accept: 'application/json',
//         },
//       })
//         .then((res) => {
//           if (res.status === 201) {
//             console.log("response with jwt is ", response);
//             setUser({ tokenId: tokenId });
//             setJwtToken(res.data.token);
//             setProfile(response.profileObj);
//             console.log(res.data.token);
//             setIsValidUser(true);
//             localStorage.setItem('user', JSON.stringify({ ...response, tokenId: tokenId }));
//             localStorage.setItem('profile', JSON.stringify(response.profileObj));
//             history.push('/courses');
//           } else {
//             setIsValidUser(false);
//           }
//         })
//         .catch((err) => {
//           setShowInvalidUserPopup(true);
//           setUser(null);
//           setProfile({});
//         });
//     }
//   };

//   const logOut = () => {
//     localStorage.removeItem('user');
//     localStorage.removeItem('profile');
//     setProfile({});
//     setUser(null);
//     setIsValidUser(false);
//     window.location.href = '/';
//   };


//   useEffect(() => {
//     const isUserLoggedIn = localStorage.getItem('user') !== null;
//     setIsValidUser(isUserLoggedIn);
//   }, []);

//   useEffect(() => {
//     const storedUser = localStorage.getItem('user');
//     const storedProfile = localStorage.getItem('profile');
//     const storedJwtToken = localStorage.getItem('jwtToken');
//     if (storedUser && storedProfile && storedJwtToken) {
//       setUser(JSON.parse(storedUser));
//       setProfile(JSON.parse(storedProfile));
//       setJwtToken(storedJwtToken);
//       setIsValidUser(true); // Update this based on your login validation logic
//     }
//   }, []);

//   useEffect(() => {
//     // console.log("Profile updated:", profile);
//   }, [profile]);

//   return (
//     <div className="container">
//       <Router>
//         <div className="content">
//           <Switch>
//             <Route
//               exact
//               path="/"
//               render={() =>
//                 // userIsValid ? (
//                 //   <Redirect to={{ pathname: "/courses", state: { profile, user } }} />
//                 // ) : (
//                   <div className="login-page-container">
//                     <h2>Teacher Control Panel</h2>
//                     <GoogleLogin
//                       clientId="840699351271-rg78od6fnaaa5bbaki88q953d9e8traj.apps.googleusercontent.com" // Replace with your Client ID
//                       buttonText="Sign in with Google"
//                       onSuccess={responseGoogle}
//                       onFailure={responseGoogle}
//                       cookiePolicy={'single_host_origin'}
//                       prompt="select_account"
//                     />
//                     <p>Please Sign In with your Google account to access the control panel</p>
//                   </div>
//                 // )
//               }
//             />
//             <ProtectedRoute path="/courses" userIsValid={userIsValid}>
//               <WebSocketProvider token={user ? user.tokenId : ''}>
//                 <Profile profile={profile} logOut={logOut} />
//                 <Courses profile={profile} user={user} logOut={logOut} jwtToken={jwtToken} />
//               </WebSocketProvider>
//             </ProtectedRoute>
//             <ProtectedRoute path="/units/:courseName" userIsValid={userIsValid}>
//               <WebSocketProvider token={user ? user.tokenId : ''}>
//                 <Profile profile={profile} logOut={logOut} />
//                 <UnitPage profile={profile} user={user} logOut={logOut} jwtToken={jwtToken} />
//               </WebSocketProvider>
//             </ProtectedRoute>
//             <ProtectedRoute path="/questions/:topic" userIsValid={userIsValid}>
//               <WebSocketProvider token={user ? user.tokenId : ''}>
//                 <QuestionsPage profile={profile} user={user} logOut={logOut} jwtToken={jwtToken} />
//               </WebSocketProvider>
//             </ProtectedRoute>
//             <ProtectedRoute path="/LiveResponsesPage" userIsValid={userIsValid}>
//               <WebSocketProvider token={user ? user.tokenId : ''}>
//                 <LiveResponsesPage profile={profile} user={user} logOut={logOut} jwtToken={jwtToken} />
//               </WebSocketProvider>
//             </ProtectedRoute>
//             <ProtectedRoute path="/Scores" userIsValid={userIsValid}>
//               <WebSocketProvider token={user ? user.tokenId : ''}>
//                 <Scores profile={profile} user={user} logOut={logOut} jwtToken={jwtToken} />
//               </WebSocketProvider>
//             </ProtectedRoute>
//           </Switch>
//         </div>
//       </Router>
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import{gapi} from 'gapi-script';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory,useLocation } from 'react-router-dom';
import Profile from './Profile';
import Courses from './Courses';
import UnitPage from './UnitPage';
import QuestionsPage from './QuestionsPage';
import LiveResponsesPage from './LiveResponsesPage';
import Scores from './Scores';
import { WebSocketProvider } from './WebSocketContext';




function ProtectedRoute({ children, userIsValid, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (userIsValid ? React.cloneElement(children, props) : <Redirect to="/" />)}
    />
  );
}
function App() {
  const storedUser = JSON.parse(localStorage.getItem('user')||'null');
  const [user, setUser] = useState(storedUser);
  const [profile, setProfile] = useState({});
  const [loggedOut, setLoggedOut] = useState(false);
  const [userIsValid, setIsValidUser] = useState(false);
  const history = useHistory();
  const location =useLocation();
  const responseGoogle = (response) => {
    if (response.error) {
      console.log("An error occurred", response.error);
    }
    const tokenId = response.tokenId;
    console.log("Token-Id", tokenId);
    if (tokenId) {
      axios.post('https://directory.getlearning.app/login', {}, {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 201) {
            setUser({ tokenId: tokenId });
            // setJwtToken(res.data.token);
            setProfile(response.profileObj);
            setIsValidUser(true);
            localStorage.setItem('user', JSON.stringify({ ...response, tokenId: tokenId }));
            localStorage.setItem('profile', JSON.stringify(response.profileObj));
            history.push('/courses');
          } else {
            setIsValidUser(false);
          }
        })
        .catch((err) => {
          // setShowInvalidUserPopup(true);
          setUser(null);
          setProfile({});
        });
    }
  };
  const logOut = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    setProfile({});
    setUser(null);
    setIsValidUser(false);
    window.location.href = '/';
  };
  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem('user') !== null;
    setIsValidUser(isUserLoggedIn);
  }, []);
  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem('user') !== null;
        setIsValidUser(isUserLoggedIn);
        if (isUserLoggedIn) {
            const previousRoute = localStorage.getItem('currentRoute');
            if (previousRoute && previousRoute !== '/' && previousRoute !== '/courses') {
                history.replace(previousRoute);
            } else {
                history.replace('/courses');
            }
        }
    }, [history]);
  useEffect(() => {
    localStorage.setItem('currentRoute', location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem('user') !== null;
    setIsValidUser(isUserLoggedIn);
    // Only after setting the user validity, try to navigate to the saved route
    if (isUserLoggedIn) {
        const previousRoute = localStorage.getItem('currentRoute');
        if (previousRoute && previousRoute !== '/') {
            history.replace(previousRoute);
        } else {
            history.replace('/courses');
        }
    }
}, []);
  return (
    <div className="container">
        <div className="content">
          <Switch>
          <Route exact path="/">
            <div className="login-page-container">
            <h2>Teacher Control Panel</h2>
            <GoogleLogin
              clientId="840699351271-rg78od6fnaaa5bbaki88q953d9e8traj.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              prompt="select_account"
            />
            <p>Please Sign In with your Institute email to access the control panel</p>
            </div>
          </Route>
            <ProtectedRoute path="/courses" userIsValid={userIsValid}>
              <WebSocketProvider token={user ? user.tokenId : ''}>
                <Profile profile={profile} logOut={logOut} />
                <Courses profile={profile} user={user} logOut={logOut}  />
              </WebSocketProvider>
            </ProtectedRoute>
            <ProtectedRoute path="/units/:courseName" userIsValid={userIsValid}>
              <WebSocketProvider token={user ? user.tokenId : ''}>
                <Profile profile={profile} logOut={logOut} />
                <UnitPage profile={profile} user={user} logOut={logOut}  />
              </WebSocketProvider>
            </ProtectedRoute>
            <ProtectedRoute path="/questions/:topic" userIsValid={userIsValid}>
              <WebSocketProvider token={user ? user.tokenId : ''}>
                <QuestionsPage profile={profile} user={user} logOut={logOut}  />
              </WebSocketProvider>
            </ProtectedRoute>
            <ProtectedRoute path="/LiveResponsesPage" userIsValid={userIsValid}>
              <WebSocketProvider token={user ? user.tokenId : ''}>
                <LiveResponsesPage profile={profile} user={user} logOut={logOut}  />
              </WebSocketProvider>
            </ProtectedRoute>
            <ProtectedRoute path="/Scores" userIsValid={userIsValid}>
              <WebSocketProvider token={user ? user.tokenId : ''}>
                <Scores profile={profile} user={user} logOut={logOut} />
              </WebSocketProvider>
            </ProtectedRoute>
            <Route path="*"><Redirect to="/"/></Route>
          </Switch>
        </div>
    </div>
  );
}
export default App;





















