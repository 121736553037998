import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom'; 
import AppWrapper from './AppWrapper';


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <GoogleOAuthProvider clientId="840699351271-rg78od6fnaaa5bbaki88q953d9e8traj.apps.googleusercontent.com">
                <AppWrapper />
                    {/* <App /> */}
                {/* </AppWrapper> */}
            </GoogleOAuthProvider>
        </Router>
        
    </React.StrictMode>,
    document.getElementById('root')
);

