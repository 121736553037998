import React, { useState, useEffect, useContext ,} from 'react';
import { useParams, Link,useHistory } from 'react-router-dom';
import axios from 'axios';
import { WebSocketContext } from './WebSocketContext';
import EndClassButton from './EndClassButton';
import './styles/unitpage.css';

function UnitPage() {
  const { courseName } = useParams();
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  
  // Access the WebSocket connection from the context
  const ws = useContext(WebSocketContext);
  const history=useHistory();

  useEffect(() => {
    const encodedCourseName = encodeURIComponent(courseName);
    axios
      .get(`https://questions.getlearning.app/api/units/${encodedCourseName}`,{

      })
      .then((res) => {
        setUnits(res.data);
      })
      .catch((error) => {
        console.log('Error fetching units:', error);
      });
  }, [courseName]);

  useEffect(() => {
    const storedUnit = localStorage.getItem('selectedUnit');
    if (storedUnit && units.includes(storedUnit)) {
      setSelectedUnit(storedUnit);
      fetchTopics(storedUnit);
    }
  }, [units]);

  const fetchTopics = (unit) => {
    setSelectedUnit(unit);
    localStorage.setItem('selectedUnit', unit);
    const encodedUnitName = encodeURIComponent(unit);
    axios
      .get(`https://questions.getlearning.app/api/topics/${encodedUnitName}`)
      .then((res) => {
        setTopics(res.data);
      })
      .catch((error) => {
        console.log('Error fetching topics:', error);
      });
  };


  const handleEndClass = () => {
    history.push('/courses');
  };
  useEffect(() => {
    window.onpopstate = function(event) {
        history.go(1);
    };
    return () => {
        window.onpopstate = null;
    };
}, []);


  // Listen for test message responses from the WebSocket server
  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        console.log('Received message from WebSocket server:', event.data);
      };
    }
  }, [ws]);
  return (
    <div className="unit-container">
      <div className="units-section">
        <h3 className="unit-heading">Units</h3>
        <div className="unit-list">
          {units.length > 0 ? (
            units.map((unit, index) => (
              <div
                key={index}
                className={`unit-item ${selectedUnit === unit ? 'active' : ''}`}
                onClick={() => fetchTopics(unit)}
              >
                {unit}
              </div>
            ))
          ) : (
            <p>No units available</p>
          )}
        </div>
      </div>
      <div className="topics-section">
        {selectedUnit && (
          <>
            <h3 className="topic-heading">Topics for {selectedUnit}</h3>
            <div className="topic-list">
              {topics.length > 0 ? (
                topics.map((topic, index) => (
                  <div key={index} className="topic-item">
                    <Link
                      to={{
                        pathname: `/questions/${topic}`,
                        ws: ws, // Passing the ws prop to QuestionsPage
                        state: { courseName: courseName }
                      }}
                      className="link-style"
                    >
                      {topic}
                    </Link>
                  </div>
                ))
              ) : (
                <p>No topics available</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        {/* Button to send the test message */}
        
      </div>
      <EndClassButton onClick={handleEndClass} />
      {/* <EndClassButton onClick={closeWebSocketConnection} /> */}
      
    </div>
    
  );
}

export default UnitPage;
