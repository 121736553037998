
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useHistory, useLocation ,Link} from 'react-router-dom';
import { WebSocketContext } from './WebSocketContext'; // Import the WebSocketContext
import './styles/questionspage.css';
import EndClassButton from './EndClassButton';

function Button({ text, onClick }) {
  return (
    <button className="action-button" onClick={onClick}>
      {text}
    </button>
  );
}

function QuestionsPage({}) {
  const { topic } = useParams();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const history = useHistory();
  const location = useLocation();
  // const { courseName } = location.state;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const ws = useContext(WebSocketContext); // Use the WebSocketContext
  const [profile, setProfile] = useState({});

  useEffect(() => {
    // Retrieve the user's profile data from localStorage
    const storedProfile = JSON.parse(localStorage.getItem('profile') || '{}');
    console.log("profile is ",storedProfile);
    // Pass the retrieved profile data to your component state
    setProfile(storedProfile);
  }, []);
  



  useEffect(() => {
    const encodedTopicName = encodeURIComponent(topic);
    axios
      .get(`https://questions.getlearning.app/api/questions/${encodedTopicName}`)
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((error) => {
        console.log('Error fetching questions:', error);
      });
  }, [topic]);

  useEffect(() => {
    const storedIndex = localStorage.getItem('selectedQuestionIndex');
    const index = parseInt(storedIndex);

    if (!isNaN(index) && index >= 0 && index < questions.length) {
      setActiveQuestionIndex(index);
      setSelectedQuestion(questions[index]);
    }
  }, [questions]);

  

  const handleQuestionClick = (question, index) => {
    setSelectedQuestion(question);
    setActiveQuestionIndex(index);
    localStorage.setItem('selectedQuestionIndex', index.toString());

    // Hide the success message when switching to another question
    setShowSuccessMessage(false);
  };



const handlePostQuestion = () => {
  if (ws && selectedQuestion) {
    const questionData = {
      question: selectedQuestion,
      type:"question",
      tcpId: profile.email,
    };
    ws.send(JSON.stringify(questionData));
    console.log("question sent to server", questionData);
    setShowSuccessMessage(true);
    localStorage.setItem('selectedQuestion', JSON.stringify(selectedQuestion));

    // console.log("question sending to the liveresponse page:-",questionData)
    // Pass the selected question data to LiveResponsesPage using location state
    history.push({
      pathname: '/LiveResponsesPage',
      state: { selectedQuestion,
      previousPath:location.pathname }, // Pass the selected question data here
    });
  }
};
  const handleChangeTopic = () => {
    localStorage.removeItem('selectedQuestionIndex'); // Remove the stored index
    // history.push(`/units/${encodeURIComponent(courseName)}`); // Redirect to the UnitsPage with courseName
    window.history.back();
  };

  const handleEndClass = () => {
    if(ws){
      //Sending acknowledgement(Class Ended) to server
      const Endclass_acknowledgement={
        type: 'end_class',
        tcpId: profile.email,
      };
      ws.send(JSON.stringify(Endclass_acknowledgement));
      console.log("End class acknowledgement sent to server");
      history.push('/Scores');// Redirect to the /Scores page
      //Requesting server for scores
      setTimeout(() => {
        const Get_Scores = {
            type: 'get_scores',
            tcpId: profile.email,
        };
        console.log('Faculty ended the class');
        ws.send(JSON.stringify(Get_Scores));
        console.log(`${Get_Scores.tcpId} requested for scores`);
    }, 5000); 
}
    
  };

  useEffect(() => {
    // This effect will be triggered whenever acknowledgmentMessage changes
    // You can add any logic here if needed
    // For example, you can show an alert, toast, or update the UI in any other way
    // whenever the acknowledgment message changes.
    console.log('Acknowledgment Message:', showSuccessMessage);
  }, [showSuccessMessage]);



  return (
    <div className="page-container">
      <div className="questions-container">
        <h2>Questions for {topic}</h2>
        <ul className="question-list">
          {questions.length > 0 ? (
            questions.map((question, index) => (
              <li
                key={index}
                className={`question-item ${activeQuestionIndex === index ? 'active-question' : ''}`}
                onClick={() => handleQuestionClick(question, index)}
              >
                {/* {question.question_text} */}
                <div dangerouslySetInnerHTML={{ __html: question.question_text }} />
              </li>
            ))
          ) : (
            <p>No questions available</p>
          )}
        </ul>
      </div>
      <div className="information-container">
        {selectedQuestion && (
          <div className="question-details sticky">
            <h3><div dangerouslySetInnerHTML={{ __html: selectedQuestion.question_type }} /></h3>
            <h3><div dangerouslySetInnerHTML={{ __html: selectedQuestion.question_text }} /></h3>
            {selectedQuestion.question_images && (
              <div>
                {selectedQuestion.question_images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="question-image"
                    width="150px"
                    height="100px"
                  />
                ))}
              </div>
            )}
            {selectedQuestion.options && (
              <div className="option-list">
                {selectedQuestion.options.map((option, index) => (
                  <div key={index} className="option-item">
                    {<div dangerouslySetInnerHTML={{ __html: option.text }} />}
                    {option.image && (
                      <img
                        src={option.image}
                        alt={`Option ${index + 1} Image`}
                        className="option-image"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="button-container">
              <Button text="Post Question" onClick={handlePostQuestion} />

              <Button text="Change Topic" onClick={handleChangeTopic} />
            </div>
            {/* Display the acknowledgment message on the webpage */}
            {showSuccessMessage && (
              <div className="acknowledgment-message">
                <p>Question posted successfully!</p>
              </div>
            )}
          </div>
        )}
      </div>
      <EndClassButton onClick={handleEndClass}/>
    </div>
  );
}

export default QuestionsPage;
