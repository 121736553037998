import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useHistory, useRouteMatch } from 'react-router-dom';
 import { WebSocketContext } from './WebSocketContext'; // Import the WebSocketContext
import './styles/courses.css';

function Courses({ profile, user }) {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [studentsData, setStudentsData] = useState([]);
  const [showStudents, setShowStudents] = useState(false);
  const [wsReady, setWsReady] = useState(false);
  
  const history = useHistory();
  const match = useRouteMatch();
  const ws = useContext(WebSocketContext); // Use the WebSocketContext


  useEffect(() => {
    if (profile.email  ) {
      axios
        .get('https://directory.getlearning.app/courses' , {
          // headers: {
          //   Authorization: `Bearer ${jwtToken}`, // Use the JWT token from props
          //   Accept: 'application/json',
          // },
        })
        .then((res) => {
          setCourses(res.data.courses);
        })
        .catch((err) =>{
          if (err.response && err.response.status === 401) {
             // Assuming you store the token in localStorage
            history.push('/login');
        } else {
            console.log(err);
        }
        });
    }
    else{
      history.push('/');
    }
  }, [profile, user,history]);

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {
        setWsReady(true);
      };
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      ws.onclose = () => {
        setWsReady(false);
      };
    }
  }, [ws]);

  const handleCourseSelect = async (course) => {
    setSelectedCourse(course);
    setShowStudents(false); // Reset showing of students on new selection

    try {
      const response = await axios.get(
        `https://directory.getlearning.app/students/${course.department}/${course.course}/${course.section}`,
        {
        //   headers: {
        //     Authorization: `Bearer ${jwtToken}`, // Use the JWT token from props
        //     Accept: 'application/json',
        //   },
        }
      );

      setStudentsData(response.data.students);
    } catch (err) {
      console.log(err);
    }
  };

  const viewStudents = (e) => {
    e.stopPropagation();
    setShowStudents(true);
  };


  const handleStartClass = async () => {
    if (selectedCourse) {
      const type = "TCP";
      const tcpId = profile.email; 
      const course = selectedCourse.course;
      const section = selectedCourse.section;
      const department = selectedCourse.department;
      const studentEmails = studentsData.map((student) => student.student_email);
      const requestData = {
        tcpId,
        course,
        section,
        department,
        studentEmails,
        type,
      };
      console.log("student data", studentEmails);

      if (ws && ws.readyState === WebSocket.OPEN) { // Check if WebSocket is ready
        ws.send(JSON.stringify(requestData),); // Send the TCP details to the server
        history.push(`/units/${encodeURIComponent(selectedCourse.course)}`, { courseName:selectedCourse.course});
        console.log("Data with token is sent to the server");
      } else {
        console.log("WebSocket connection is not ready.");
      }
    }
  };




  return (
    <div class="course_page">
      <h1>Courses</h1>
      <table>
        <thead>
          <tr>
            <th>Department</th>
            <th>Course</th>
            <th>Section</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr key={index} 
             className={`course-row ${selectedCourse === course ? 'selected-course' : ''}`}
             onClick={() => handleCourseSelect(course)
    }
             >
              <td>{course.department}</td>
              <td>{course.course}</td>
              <td>{course.section}</td>
              <td>
              <button 
              className={`view-students-button ${selectedCourse === course ? '' : 'disabled-button'}`}
               onClick={viewStudents}
               disabled={selectedCourse !== course}
               >View Students
               </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showStudents && (
        <div className='students-list-contianer'>
        <div className="students-list">
          <span className="students_text"><h2>Students</h2></span>
          <ul>
            {studentsData.map((student, index) => (
              <li key={index}>
                {student.student_name}
              </li>
            ))}
          </ul>
        </div>
        </div>
      )}

      {selectedCourse && (
        <div className="start-class">
          <button onClick={handleStartClass}>Start class</button>
          {/* <UnitPage ws={ws} /> */}
        </div>
      )}
    </div>
  );
}



export default Courses;
