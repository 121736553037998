import React, {useRef, useEffect, useState} from 'react';
import './styles/profile.css';

export default function Profile({ profile, logOut }) {
    const profileRef = useRef(null);
    const [showProfile, setShowProfile] = useState(false);

  
    const handleProfileClick = () => {
      setShowProfile(true);
    };
  
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false);
      }
    };
    
    const handleLogout = (event) => {
      setShowProfile(false);
      logOut();
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    return (
      <div className="profile">
        {profile.imageUrl && (
          <img
            src={profile.imageUrl}
            alt="user image"
            className="profile-image"  
            onClick={handleProfileClick}
          />
        )}
        {showProfile && (
          <div className="profile-popup" ref={profileRef}>
            <h3>Profile:</h3>
            <div className="profile-info">
              <div className="profile-image-container">
                <img src={profile.imageUrl} alt="profile" className="profile-image" />
              </div>
              <div className="profile-details">
                <h4 className="profile-name">{profile.name}</h4>
                <p className="profile-email">{profile.email}</p>
              </div>
            </div>
            <button onClick={(event) => handleLogout(event)} className="profile-logout-button">
              Sign out
            </button>
          </div>
        )}
      </div>
    );
  }