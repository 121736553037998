import React, { createContext, useState, useEffect } from 'react';
const WebSocketContext = createContext();
const WebSocketProvider = ({ token,children }) => {
  const [ws, setWs] = useState(null);
  useEffect(() => {
    console.log("websocket is not connected to server yet");
	var newWs;
	  try {
		  newWs = new WebSocket (`wss://activesession.getlearning.app:8888?token=${token}&connection_type="TCP"`);
}
	catch(err){
		console.log("Error;",err.message,"---------");
	}
//    const newWs = new WebSocket(`wss://activesession.getlearning.app?token=${token}&connection_type="TCP"`);
    console.log("websocket is connected");
        setWs(newWs);

    return () => {
      // Clean up the WebSocket connection on unmount
      newWs.close();
    };
  }, [token]);
  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketContext, WebSocketProvider };
