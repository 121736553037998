// EndClassButton.js

import React from 'react';
import './App.css'; // Import the CSS file for the button styles

const EndClassButton = ({ onClick }) => {
  return (
    <button className="endclass-button" onClick={onClick}>
      <i className="material-icons">logout</i>
      End Class
    </button>
  );
};

export default EndClassButton;
