import React, { useEffect, useContext, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { WebSocketContext } from './WebSocketContext';
import './styles/scores.css';
function Scores() {
    const history = useHistory();
    const ws = useContext(WebSocketContext);
    const [classDetails, setClassDetails] = useState({});
    const [studentScores, setStudentScores] = useState([]);
    const [topics, setTopics] = useState([]);
    const [topicslength,setTopicLength] = useState(null);
    const currentDate = new Date().toLocaleDateString();
    const [loading, setLoading] = useState(true);

   

    useEffect(() => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            setLoading(true);
            ws.onmessage = (message) => {
                console.log("Got message from server to scores page");
                const receivedData = JSON.parse(message.data);
                const scoresData = receivedData.data;
                // Check the type of received data
                if (receivedData.type === 'scores') {
                    console.log("Got scors data from server.");
                    console.log('Received data:',receivedData);
                    console.log('Received scores_data:', scoresData);
                    // Extract topics and total_score from one student's data
                    const firstStudentKey = Object.keys(scoresData)[0];
                    const firstStudent = scoresData[firstStudentKey];

                    setTopics(firstStudent.topics);
                    console.log("Topics:",topics);
                    setTopicLength(firstStudent.topics.length);
                    // Reformat student scores data for rendering
                    const formattedStudentScores = {};
                    Object.keys(scoresData).forEach(studentKey => {
                        const studentData = scoresData[studentKey];
                        formattedStudentScores[studentData.student_name] = `${studentData.student_score}/${studentData.Total_score}`;
                    });
                    setStudentScores(formattedStudentScores);
                    console.log("Formatted student scores:",studentScores);
                    setLoading(false);
                }
                else if (receivedData.type === 'active_class') {
                    console.log("Received active class data form server");
                    const activeClassData = receivedData.data;
                    console.log('Received ActiveClass:', activeClassData);
                    setClassDetails({
                        section: activeClassData[0],
                        course: activeClassData[1],
                        department: activeClassData[2]
                    });
                }
            };
        }
    }, [ws]);
    const handleOKClick = () => {
        // Redirect to the courses page
        history.push('/');
        localStorage.clear();
    };
    return (
        <div className="report">
            {loading ? (
                <div className="loading">
                    <p className='loading-text'>Fetching Scores</p>
                    <div className="loader">
                        
                        </div>
                </div>
            ) : (
                <>
                    <div className="heading">
                        <h1>Class Report</h1>
                    </div>
                    <div className="class-details">
                        <div className="head">
                            <h2>Class Details</h2>
                        </div>
                        <div className="components">
                            <div className="detail-item">
                                <p className="detail-label">Date</p>
                                <p className="detail-value">{currentDate}</p>
                            </div>
                            {classDetails && (
                                <>
                                    <div className="detail-item">
                                        <p className="detail-label">Department</p>
                                        <p className="detail-value">{classDetails.department}</p>
                                    </div>
                                    <div className="detail-item">
                                        <p className="detail-label">Section</p>
                                        <p className="detail-value">{classDetails.section}</p>
                                    </div>
                                    <div className="detail-item">
                                        <p className="detail-label">Course</p>
                                        <p className="detail-value">{classDetails.course}</p>
                                    </div>
                                    <div className="detail-item">
                                        <p className="detail-label">Number of Topics</p>
                                        <p className="detail-value">{topicslength}</p>
                                    </div>
                                    <div className="detail-item">
                                        <p className="detail-label">Topics</p>
                                        <p className="detail-value">{topics.join(', ')}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="student-scores">
                        <div className="head">
                            <h2>Student Scores</h2>
                        </div>
                        {studentScores && Object.keys(studentScores).map(studentName => (
                            <div className='scores' key={studentName}>
                                <p>{studentName}</p>
                                <p>{studentScores[studentName]}</p>
                            </div>
                        ))}
                    </div>
                    <div className="ok-button">
                        <button onClick={handleOKClick}>OK</button>
                    </div>
                </>
            )}
        </div>
    );
    
                    
}
export default Scores;