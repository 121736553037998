import {
  BiShow,
  BiStopCircle,
  BiCheckCircle,
  BiRepeat,
  BiArrowToRight,
} from "react-icons/bi";
import React, { useState, useEffect, useContext } from "react";
import WordCloud from "react-wordcloud";
import { Bar } from "react-chartjs-2";
import { WebSocketContext } from "./WebSocketContext";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
} from "chart.js";
import "./styles/liveresponsepage.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EndClassButton from "./EndClassButton";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(CategoryScale, LinearScale, BarController, BarElement);

const LiveResponsesPage = ({}) => {
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch topics data here, and when data is received:
    // setTopics(data);
    setIsLoading(false); // Set isLoading to false when data is ready
  }, []);

  useEffect(() => {
    // Retrieve the user's profile data from localStorage
    const storedProfile = JSON.parse(localStorage.getItem("profile") || "{}");
    console.log("profile is ", storedProfile);
    // Pass the retrieved profile data to your component state
    setProfile(storedProfile);
  }, []);

  const options = {
    rotations: 1, // No rotations allowed
    rotationAngles: [0], // Set rotation angle to 0 degrees
    scale: "linear", // Use linear scaling for word sizes
    fontFamily: "arial", // You can choose a different font if you like
    fontStyle: "normal",
    fontWeight: "normal",
    fontSizes: [10, 80],
  };

  const sampleData = {
    labels: [],
    datasets: [
      {
        label: "Question 1",
        backgroundColor: "",
        borderColor: "black",
        borderWidth: 1,
        data: [0, 0, 0, 0],
      },
    ],
  };

  const [data, setData] = useState(sampleData);

  const words = data.labels.map((label, index) => ({
    text: label,
    value: data.datasets[0].data[index],
  }));

  const [wordData, setWordData] = useState([]); // for word cloud
  const [chatResponses, setChatResponses] = useState([]);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [showStopButton, setShowStopButton] = useState(true);
  const ws = useContext(WebSocketContext);
  // const selectedQuestion = (useHistory().location.state || {}).selectedQuestion;    // console.log("live response page got the question data", selectedQuestion);

  const [nextQuestionBtnDisabled, setNextQuestionBtnDisabled] = useState(true);
  const history = useHistory();
  const [answerDisplayed, setAnswerDisplayed] = useState(false);
  // const [responsesStopped, setResponsesStopped] = useState(false);
  const [repostQuestionBtnDisabled, setRepostQuestionBtnDisabled] =
    useState(false);
  // console.log("live response page got the question data", liveResponseQuestion);

  const selectedQuestion =
    history.location.state?.selectedQuestion ||
    JSON.parse(localStorage.getItem("selectedQuestion") || "null");

  if (!selectedQuestion) {
    // Handle the case when selectedQuestion is neither in the location state nor in local storage
    console.error("No selected question found!");
  }

  //---------------------------------------------
  useEffect(() => {
    fetchData();

    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log("Received Websocket message");
      ws.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.type === "response") {
          if (data.question_type === "Single Response") {
            updateWordData(data.response);
            console.log("Single response funciton is updated");
          } else {
            updateData(data.response);
            console.log("MCQ or MSQ funciton is updated");
          }
          console.log("Got the data from TA", data);
        }
      };
    }
  }, [ws]);

  //-----------------------------------------------------
  const fetchData = () => {
    setTimeout(() => {
      const options = selectedQuestion.options.map((option, index) => {
        return String.fromCharCode(97 + index);
      });

      const counts = new Array(options.length).fill(0); // Initialize counts as zeros

      const updatedData = {
        labels: options,
        datasets: [
          {
            label: selectedQuestion.question_text,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            data: counts,
          },
        ],
      };
      setData(updatedData);
    });
  };
  //---------------------------------------------------------------------------------------------

  const updateData = (response) => {
    console.log("Received response:", response);
    setData((prevData) => {
      const updatedData = { ...prevData };

      response.forEach((selectedOption) => {
        const index = selectedOption.charCodeAt(0) - "a".charCodeAt(0); // Convert 'a' to 0, 'b' to 1, and so on
        if (index >= 0 && index < selectedQuestion.options.length) {
          updatedData.datasets[0].data[index]++;
        }
        console.log("Updated data:", updatedData);
      });
      
      return updatedData;
    });
  };

  const updateWordData = (response) => {
    setWordData((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex((word) => word.text === response);

      if (index !== -1) {
        // If the word already exists, increment its value
        updatedData[index].value++;
      } else {
        // If the word does not exist, add it to the array with a value of 1
        updatedData.push({ text: response, value: 1 });
      }

      return updatedData;
    });
  };

  //----------------------------------------------------------------------------------

  const formatOptionText = (text) => {
    const div = document.createElement("div");
    div.innerHTML = text;
    return div.textContent || div.innerText || "";
  };

  const handleEndClass = () => {
    // Add any logic here to clean up or end the class if needed
    // Redirect to the /courses page
    history.push("/");
  };

  const handleDisplayAnswer = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      // Extracting the correct answer's option(s)
      let correctOptions = [];

      selectedQuestion.options.forEach((option, index) => {
        const isCorrectOption =
          (option.text &&
            option.image &&
            option.text === selectedQuestion.answer.text &&
            option.image === selectedQuestion.answer.image) ||
          (option.text &&
            !option.image &&
            option.text === selectedQuestion.answer.text) ||
          (!option.text &&
            option.image &&
            option.image === selectedQuestion.answer.image);

        if (isCorrectOption) {
          correctOptions.push(String.fromCharCode(97 + index)); // Convert index to a letter, e.g., 0 -> 'a', 1 -> 'b', ...
        }
      });

      const message = {
        type: "display_answer",
        tcpId: profile.email,
        answer: correctOptions, // Now, this will always be an array
      };

      ws.send(JSON.stringify(message));
      console.log("Message with correct answer option(s) sent to server");
      setAnswerDisplayed(true); // Update state to indicate answer has been displayed
    }
  };

  const handleNextQuestion = () => {
    // console.log("Previous path is ,",previousPath);
    // history.push(previousPath);
    window.history.back(); // Navigate back to the previous path
  };

  const handleAcceptResponses = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = {
        type: "accept_responses",
        tcpId: profile.email,
      };
      ws.send(JSON.stringify(message));
      console.log("Sent message to server on accepting responses");
      setShowStopButton(true);
      setShowAcceptButton(false);
    }
  };
  const handleStopResponses = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = {
        type: "Stop_accepting_responses",
        // Add any data you want to send to the server
        // For example, you might want to include the question ID or some other identifier
        tcpId: profile.email,
      };
      ws.send(JSON.stringify(message));
      setShowAcceptButton(true);
      setShowStopButton(false);
    }
  };

  const handleRepost = () => {
    if (ws && selectedQuestion) {
      const questionData = {
        question: selectedQuestion,
        type: "question",
        tcpId: profile.email,
        repost: true,
      };
      ws.send(JSON.stringify(questionData));
      console.log("question sent to server", questionData);

      // Reset the bar graph data
      const updatedData = {
        labels: selectedQuestion.options.map((option, index) => {
          return String.fromCharCode(97 + index);
        }),
        datasets: [
          {
            label: selectedQuestion.question_text,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            data: selectedQuestion.options.map((option, index) => {
              return 0;
            }),
          },
        ],
      };
      setData(updatedData);
      setWordData([]);
    }
  };

  const NavBar = () => {
    return (
      <nav className="navbar">
        <span>Live Response page name</span>
      </nav>
    );
  };

  return (
    <div className="live-responses-container">
      <NavBar />
      <div className="page-container">
        {selectedQuestion && (
          <div className="question-section">
            <h3
              dangerouslySetInnerHTML={{
                __html: selectedQuestion.question_text,
              }}
            />
            {selectedQuestion.question_images && (
              <div>
                {selectedQuestion.question_images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="question-image"
                    width="300px"
                    height="200px"
                  />
                ))}
              </div>
            )}
            {selectedQuestion.options && (
              <div className="option-list">
                {selectedQuestion.options.map((option, index) => {
                  let isCorrectOption = false;

                  if (selectedQuestion.question_type === "Multiple Choice") {
                    // For single-select questions (MCQ)
                    isCorrectOption =
                      (option.text === selectedQuestion.answer.text ||
                        !selectedQuestion.answer.text) &&
                      (option.image === selectedQuestion.answer.image ||
                        !selectedQuestion.answer.image);
                  } else if (
                    selectedQuestion.question_type === "Check All That Apply"
                  ) {
                    // For multiple-select questions (MSQ)
                    isCorrectOption = selectedQuestion.answer.some(
                      (correctAnswer) => {
                        return (
                          (correctAnswer.text === option.text ||
                            !correctAnswer.text) &&
                          (correctAnswer.image === option.image ||
                            !correctAnswer.image)
                        );
                      }
                    );
                  }

                  // Use a class name to differentiate between correct and incorrect options
                  const optionClassName = isCorrectOption
                    ? "option-item correct-answer"
                    : "option-item";
                  const optionLabel = `${String.fromCharCode(97 + index)}) `;

                  return (
                    <div key={index} className={optionClassName}>
                      <div className="option-container">
                        <span>{optionLabel}</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatOptionText(option.text),
                          }}
                        />
                        {option.image && (
                          <img
                            src={option.image}
                            alt={`Option ${index + 1} Image`}
                            className={`option-image ${
                              isCorrectOption ? "correct-image" : ""
                            }`}
                            width="150px"
                            height="100px"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                {selectedQuestion.question_type === "Single Response" && (
                  <div className="answer-section">
                    <h4>Answer:</h4>
                    <p>{selectedQuestion.answer.text}</p>{" "}
                    {/* Adjust as needed to match the structure of your answer object */}
                  </div>
                )}
              </div>
            )}
            {selectedQuestion.answer && (
              <div className="answer-section">
                <div></div>
              </div>
            )}
          </div>
        )}
        {data && (
          <div className="bar-graphs">
            {selectedQuestion.question_type === "Single Response" ? (
              <div className="word-cloud-container">
                <WordCloud words={wordData} options={options} />
              </div>
            ) : (
              <div className="bar-graph">
                <Bar
                  data={{
                    labels: data.labels,
                    datasets: data.datasets,
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    layout: { padding: { top: 30 } },
                    scales: {
                      y: {
                        display: false,
                        beginAtZero: true,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        enabled: true,
                        anchor: "end", // Position of the data label (end = top of the bar)
                        align: "top", // Alignment of the data label (top of the bar)
                        color: "red",

                        // Color of the data label
                        formatter: (value) => (value / 2).toString(),
                      },
                    },
                  }}
                  plugins={[ChartDataLabels]}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div class="button-row">
  { showStopButton && (
  <button className="action-button stop-question-button" onClick={handleStopResponses}>
    <BiStopCircle size={20} style={{ marginRight: 10 }} /> Stop accepting responses
  </button> )}
  <div>
    {showAcceptButton && (
      <button className="action-button accept-response-button" onClick={handleAcceptResponses}>
        <BiCheckCircle size={20} style={{ marginRight: 10 }} /> Accept Responses
      </button>
    )}
    <button className="action-button repost-question-button" onClick={handleRepost}>
      <BiRepeat size={20} style={{ marginRight: 10 }} /> Repost Question
    </button>
  </div>
</div> */}

      <div class="button-container"></div>
      <div class="display_btn_container">
        <button
          className="action-button display-answer-button"
          onClick={handleDisplayAnswer}
        >
          <BiShow size={20} style={{ marginRight: 10 }} /> Display Answer to
          Student
        </button>
      </div>

      <div class="nextquestion">
        <button className="next-question-button" onClick={handleNextQuestion}>
          {" "}
          Next Question
          <BiArrowToRight size={20} style={{ marginLeft: 10 }} />
        </button>
      </div>
      <EndClassButton onClick={handleEndClass} />
    </div>
  );
};

export default LiveResponsesPage;
